import { useCallback } from 'react';

import useSWR, { SWRConfiguration } from 'swr';

import { getSegmentFromCookies } from '~/shared/utils/getSegmentFromCookies';
import { api } from '~/shared/services/api';

import { formatCompany } from '../utils/formatCompany';
import { ICompany } from '../interfaces/ICompany';
import { CompanyRequestDTO } from '../dtos/CompanyRequestDTO';

interface UseCompanies {
  companies: ICompany[];
  update(id: string, data: CompanyRequestDTO): Promise<void>;
  create(data: CompanyRequestDTO): Promise<ICompany>;
  isLoading: boolean;
  checkCompanyAlreadyExists(document: string, id?: string): Promise<boolean>;
}

export const useCompanies = (
  options?: SWRConfiguration<ICompany[]>
): UseCompanies => {
  const segment = getSegmentFromCookies();

  const {
    data: companies,
    mutate,
    error,
  } = useSWR<ICompany[]>(`/segments/${segment}/companies`, options);

  const formattedCompanies =
    companies?.map((company) => formatCompany(company)) || [];

  const create = useCallback(
    async (data: CompanyRequestDTO): Promise<ICompany> => {
      const segmentId = getSegmentFromCookies();

      const response = await api.post(`/segments/${segmentId}/companies`, data);

      mutate((old) => {
        const newCompanies =
          old?.length > 0 ? [response.data, ...old] : [response.data];

        return newCompanies;
      }, false);

      return response.data;
    },
    [mutate]
  );

  const update = useCallback(
    async (id: string, data: CompanyRequestDTO) => {
      const response = await api.put(
        `/segments/${segment}/companies/${id}`,
        data
      );

      mutate((old) => {
        return old?.map((item) => {
          if (item._id === id) {
            return {
              ...item,
              ...response.data,
            };
          }

          return item;
        });
      }, false);
    },
    [mutate, segment]
  );

  const checkCompanyAlreadyExists = useCallback(
    async (document: string, id?: string) => {
      const response = await api.get(`/segments/${segment}/companies/check`, {
        params: {
          id,
          document,
        },
      });

      if (response.data) {
        return true;
      }

      return false;
    },
    [segment]
  );

  return {
    companies: formattedCompanies,
    isLoading: !error && !companies,
    update,
    create,
    checkCompanyAlreadyExists,
  };
};
