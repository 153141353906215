/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useEffect, useState } from 'react';

import Script from 'next/script';
import Head from 'next/head';
import App, { AppProps, AppContext, AppInitialProps } from 'next/app';

import localFont from '@next/font/local';

import { GlobalStyle } from '~/shared/styles/global';
import { loadWhiteLabelSettings } from '~/shared/services/whiteLabelSettings';
import { DefaultLayout } from '~/shared/layouts/DefaultLayout';
import { AuthLayout } from '~/shared/layouts/AuthLayout';
import { ISettings } from '~/shared/interfaces/ISettings';
import { SettingsProvider } from '~/shared/hooks/settings';
import { AppProvider } from '~/shared/hooks';
import { routes } from '~/shared/constants/routes';

import '~/shared/components/TopProgressBar';

const myFont = localFont({
  preload: true,
  src: [
    {
      path: '../shared/assets/fonts/ItauDisplay/ItauDisplay_Regular.woff',
      weight: '400',
    },
    {
      path: '../shared/assets/fonts/ItauDisplay/ItauDisplay_Black.woff',
      weight: '800',
    },
    {
      path: '../shared/assets/fonts/ItauDisplay/ItauDisplay_Bold.woff',
      weight: '700',
    },
    {
      path: '../shared/assets/fonts/ItauDisplay/ItauDisplay_Light.woff',
      weight: '300',
    },
  ],
});

function MyApp({ Component, pageProps, router }: AppProps): JSX.Element {
  const [settings, setSettings] = useState<ISettings>({} as ISettings);
  const authRoutes = [...Object.values(routes.auth)];

  const Layout =
    authRoutes.includes(router.pathname === '/' ? '/' : router.pathname) ||
    router.asPath === routes.setup
      ? AuthLayout
      : DefaultLayout;

  useEffect(() => {
    const closeInputAutocompleteMenu = (): void => {
      const activeElement = document.activeElement as any;

      if (activeElement) {
        activeElement.blur();
        // activeElement.focus();
      }
    };

    const mainElement = document.getElementById('main-element');

    if (!mainElement) return () => null;

    mainElement.addEventListener('scroll', closeInputAutocompleteMenu);

    return () => {
      mainElement.removeEventListener('scroll', closeInputAutocompleteMenu);
    };
  }, []);

  useEffect(() => {
    (async () => {
      const { host } = window.location;
      const whitLabelSettings = window.sessionStorage.getItem(
        `@settings-${host}`
      );

      if (whitLabelSettings && whitLabelSettings !== 'null') {
        const parse = JSON.parse(whitLabelSettings) as ISettings;

        setSettings(parse);
      } else {
        const _settings = await loadWhiteLabelSettings(host);

        window.sessionStorage.setItem(
          `@settings-${host}`,
          JSON.stringify(_settings)
        );

        setSettings(_settings);
      }
    })();
  }, []);

  return (
    <SettingsProvider settings={settings}>
      <AppProvider>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>

        <Script
          src="https://www.googletagmanager.com/gtag/js?id=UA-206074301-1"
          strategy="afterInteractive"
        />

        <Script id="gtag" strategy="afterInteractive">
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){window.dataLayer.push(arguments);}
              gtag('js', new Date());

              gtag('config', 'UA-206074301-1');
            `}
        </Script>

        <Script id="google-analytics" strategy="afterInteractive">
          {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-MNT5J9P');
            `}
        </Script>

        <GlobalStyle />

        <Layout className={myFont.className}>
          {/* @ts-ignore */}
          <Component {...pageProps} />
        </Layout>
      </AppProvider>
    </SettingsProvider>
  );
}

MyApp.getInitialProps = async (
  appContext: AppContext
): Promise<AppInitialProps> => {
  const appProps = await App.getInitialProps(appContext);
  return { ...appProps };
};

export default MyApp;
