import { ToolbarProvider } from '~/shared/hooks/toolbar';
import { OnboardingProvider } from '~/modules/onboarding/hooks/onboarding';

import { FCWithChildren } from '~/shared/types/FCWithChildren';

import { OverdueSubscriptionsWarning } from '~/shared/components/OverdueSubscriptionsWarning';
import { Toolbar } from '../../components/Toolbar';
import { SideBar } from '../../components/SideBar';
import { PageJourneyProvider } from '../../../modules/pageJourney/components/PageJourneyProvider';

import { Container, Content, MainWrapper } from './styles';

interface IDefaultLayoutProps {
  className: string;
}

export const DefaultLayout: FCWithChildren<IDefaultLayoutProps> = ({
  children,
  className,
}) => {
  return (
    <OnboardingProvider>
      <PageJourneyProvider>
        <Container className={className}>
          <SideBar />

          <ToolbarProvider>
            <MainWrapper id="main-wrapper">
              <main id="main-element">
                <OverdueSubscriptionsWarning />

                <Toolbar />

                <Content>{children}</Content>
              </main>
            </MainWrapper>
          </ToolbarProvider>
        </Container>
      </PageJourneyProvider>
    </OnboardingProvider>
  );
};
