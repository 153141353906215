import styled, { css } from 'styled-components';

interface ContainerProps {
  isActive: boolean;
  isDisabled: boolean;
}

export const Container = styled.li<ContainerProps>`
  width: 100%;

  a {
    width: 100%;

    display: flex;
    align-items: center;

    padding: ${({ theme }) => `${theme.spacing.xs} ${theme.spacing.md}`};
    background: ${({ theme }) => theme.colors.surface};

    transition: all 0.4s;

    position: relative;

    svg {
      min-width: 15px;
      width: 15px;
      min-height: 15px;
      width: 15px;
      margin-right: ${({ theme }) => theme.spacing.xs};
      color: ${({ theme }) => theme.colors.primary};
    }

    span {
      flex: 1;
      font-size: 14px;
      color: ${({ theme }) => theme.colors.onSurface};
      line-height: 14px;
      white-space: nowrap;
      overflow: hidden;

      transition: all 0.3s;
    }

    .badge {
      margin-left: auto;
      padding: 5px 8px;
      font-size: 9px;
      line-height: 7px;

      /* position: absolute;
      right: ${({ theme }) => theme.spacing.md}; */

      margin-left: ${({ theme }) => theme.spacing.xs};
      z-index: 10;
    }

    ${({ isActive }) =>
      isActive
        ? css`
            background: ${({ theme }) => theme.colors.background};

            svg {
              color: ${({ theme }) => theme.colors.primary};
            }

            span {
              font-weight: 700;
              color: ${({ theme }) => theme.colors.onBackground};
            }
          `
        : css`
            &:hover {
              opacity: 0.6;
            }
          `}
  }

  ${({ isDisabled }) =>
    isDisabled &&
    css`
      cursor: not-allowed;

      a {
        opacity: 0.6;

        pointer-events: none;
      }
    `}
`;
