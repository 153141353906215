/* eslint-disable no-nested-ternary */
/* eslint-disable react/display-name */
import { FC, memo, useMemo } from 'react';

import { TbArrowsUpDown } from 'react-icons/tb';
import { SiMarketo } from 'react-icons/si';
import {
  MdDevices,
  MdEvent,
  MdLocationOn,
  MdOutlineDashboardCustomize,
  MdOutlineMenuBook,
  MdVerifiedUser,
} from 'react-icons/md';
import {
  IoBusiness,
  IoCalculator,
  IoHome,
  IoLayers,
  IoPeople,
  IoPricetag,
  IoCart,
} from 'react-icons/io5';
import { IoIosPeople } from 'react-icons/io';
import { HiSpeakerphone, HiUpload } from 'react-icons/hi';
import { FiMonitor } from 'react-icons/fi';
import {
  FaBarcode,
  FaBoxes,
  FaCashRegister,
  FaCube,
  FaHandHoldingUsd,
  FaSatelliteDish,
  FaTruck,
} from 'react-icons/fa';
import {
  BsFillLightningChargeFill,
  BsEnvelopeOpenFill,
  BsTable,
  BsDownload,
} from 'react-icons/bs';
import { BiNotepad, BiTransfer } from 'react-icons/bi';
import {
  AiFillDollarCircle,
  AiFillWallet,
  AiOutlineAudit,
} from 'react-icons/ai';
import { IconType } from 'react-icons';

import { useAuth } from '~/modules/auth/hooks/auth';

import { validateUserPermissions } from '~/shared/utils/validateUserPermissions';
import { useDynamicSidebar } from '~/shared/hooks/dynamicSidebar';
import { SidebarPagesEnum } from '~/shared/enums/SidebarPagesEnum';
import { ReferenceIdEnum } from '~/shared/enums/ReferenceIdEnum';
import { routes } from '~/shared/constants/routes';
import { permissions } from '~/shared/constants/permissions';

import { Container } from './styles';
import { NavSection } from './NavSection';
import { NavItem } from './NavItem';

interface DynamicTabProps {
  icon: IconType;
  href: string;
  referenceId: ReferenceIdEnum;
  permissions?: Array<string>;
  shouldMatchExactHref?: boolean;
  comingSoon?: boolean;
  isNew?: boolean;
  isDisabled?: boolean;
  tag?: string;
}

interface Tab extends DynamicTabProps {
  label: string;
}

interface NavSectionProps {
  title?: string;
  shouldTabsStartsVisible?: boolean;
  tabs: Tab[];
  tag: string;
}

export const tabProps: { [key: string]: DynamicTabProps } = {
  [SidebarPagesEnum.HOME]: {
    icon: IoHome,
    href: routes.home,
    referenceId: ReferenceIdEnum.home,
  },
  [SidebarPagesEnum.LIST_COMPANIES]: {
    icon: IoBusiness,
    href: routes.companies.list,
    referenceId: ReferenceIdEnum.companies,
    permissions: [permissions.companies.list],
  },
  [SidebarPagesEnum.USER_GROUPS]: {
    icon: MdVerifiedUser,
    href: routes.users.groups.list,
    referenceId: ReferenceIdEnum['user-groups'],
    permissions: [permissions.groups.list],
    shouldMatchExactHref: true,
  },
  [SidebarPagesEnum.LIST_USERS]: {
    icon: IoPeople,
    href: routes.users.list,
    referenceId: ReferenceIdEnum.users,
    permissions: [permissions.users.list],
    shouldMatchExactHref: true,
  },
  [SidebarPagesEnum.LIST_MARKETPLACE_MANAGEMENT]: {
    icon: FaSatelliteDish,
    href: routes.marketplacesManagement.list(),
    referenceId: ReferenceIdEnum['marketplaces-management'],
  },
  [SidebarPagesEnum.LIST_ORDERS]: {
    icon: AiFillDollarCircle,
    href: routes.orders.list,
    referenceId: ReferenceIdEnum.orders,
    permissions: [permissions.orders.list],
    shouldMatchExactHref: true,
  },
  [SidebarPagesEnum.POST_ORDERS]: {
    tag: 'orders-posts',
    icon: FaTruck,
    href: routes.ordersPosts.list,
    referenceId: ReferenceIdEnum['orders-posts'],
  },
  [SidebarPagesEnum.LIST_CASHIERS]: {
    referenceId: ReferenceIdEnum.cashiers,
    icon: FaCashRegister,
    href: routes.cashiers.list,
    permissions: [permissions.cashiers.list],
  },
  [SidebarPagesEnum.COLLECTS_CONTROL]: {
    referenceId: ReferenceIdEnum['collects-control'],
    icon: BsEnvelopeOpenFill,
    href: routes.collectsControl,
    permissions: [permissions.collectsControl.list],
  },
  [SidebarPagesEnum.CASHIERS_REPORT]: {
    referenceId: ReferenceIdEnum['cashiers-reports'],
    icon: BiNotepad,
    href: routes.cashiers.reports,
    isNew: false,
  },
  [SidebarPagesEnum.LIST_CUSTOMERS]: {
    referenceId: ReferenceIdEnum.customers,
    icon: IoPeople,
    href: routes.customers.list,
    permissions: [permissions.customers.list],
  },
  [SidebarPagesEnum.LIST_SELLERS]: {
    referenceId: ReferenceIdEnum.sellers,
    icon: IoIosPeople,
    href: routes.sellers.list,
    isNew: false,
    permissions: [permissions.sellers.list],
  },
  [SidebarPagesEnum.ABANDONED_CARTS]: {
    referenceId: ReferenceIdEnum['abandoned-carts'],
    icon: IoCart,
    href: routes.abandonedCarts.list,
    comingSoon: true,
    isDisabled: true,
  },
  [SidebarPagesEnum.ORDERS_REPORT]: {
    referenceId: ReferenceIdEnum['orders-reports'],
    icon: BiNotepad,
    href: routes.sales.reports,
    isNew: false,
  },
  [SidebarPagesEnum.LIST_PRODUCTS]: {
    referenceId: ReferenceIdEnum.products,
    icon: IoPricetag,
    href: routes.products.list,
    permissions: [permissions.products.list],
    shouldMatchExactHref: true,
  },
  [SidebarPagesEnum.LIST_CATEGORIES]: {
    referenceId: ReferenceIdEnum.categories,
    icon: IoLayers,
    href: routes.categories.list,
    permissions: [permissions.categories.list],
  },
  [SidebarPagesEnum.LIST_CATALOGS]: {
    referenceId: ReferenceIdEnum.catalogs,
    icon: MdOutlineMenuBook,
    href: routes.catalogs.list,
    isNew: true,
    permissions: [permissions.catalogs.list],
  },
  [SidebarPagesEnum.LIST_BRANDS]: {
    referenceId: ReferenceIdEnum.brands,
    icon: IoPricetag,
    href: routes.brands.list,
  },
  [SidebarPagesEnum.LIST_ENVIRONMENTS]: {
    referenceId: ReferenceIdEnum['product-environments'],
    icon: MdLocationOn,
    href: routes.products.environments.list,
  },
  [SidebarPagesEnum.LIST_TAXATION_RULES]: {
    referenceId: ReferenceIdEnum['taxation-rules'],
    icon: IoCalculator,
    href: routes.taxationRules.list,
    permissions: [permissions.taxationRules.list],
  },
  [SidebarPagesEnum.PRODUCTS_REPORTS]: {
    referenceId: ReferenceIdEnum['products-reports'],
    icon: BiNotepad,
    href: routes.products.reports,
    isNew: false,
  },
  [SidebarPagesEnum.LIST_PROMOTIONS]: {
    referenceId: ReferenceIdEnum.promotions,
    icon: HiSpeakerphone,
    href: routes.promotions.list,
    comingSoon: false,
    isDisabled: false,
    isNew: false,
  },
  [SidebarPagesEnum.LIST_STOCKLOCALS]: {
    referenceId: ReferenceIdEnum['stock-locals'],
    icon: FaBoxes,
    href: routes.stockLocals.list,
    shouldMatchExactHref: true,
  },
  [SidebarPagesEnum.LIST_STOCK_ADJUSTMENTS]: {
    referenceId: ReferenceIdEnum['stock-adjustment'],
    icon: FaCube,
    href: routes.stockAdjustments.list,
  },
  [SidebarPagesEnum.LIST_STOCK_MONITORING]: {
    referenceId: ReferenceIdEnum['stock-monitoring'],
    icon: FiMonitor,
    href: routes.stockMonitoring.list,
  },
  [SidebarPagesEnum.LIST_STOCK_TRANSFER]: {
    referenceId: ReferenceIdEnum['stock-transfer-group'],
    icon: BiTransfer,
    href: routes.stockTransferGroups.list,
  },
  [SidebarPagesEnum.STOCKLOCALS_REPORTS]: {
    referenceId: ReferenceIdEnum['stock-reports'],
    icon: BiNotepad,
    href: routes.stockLocals.reports,
    isNew: false,
    shouldMatchExactHref: true,
  },
  [SidebarPagesEnum.LIST_INTEGRATIONS]: {
    referenceId: ReferenceIdEnum.integrations,
    icon: SiMarketo,
    href: routes.apps.list(),
  },
  [SidebarPagesEnum.LIST_EVENTS]: {
    referenceId: ReferenceIdEnum.events,
    icon: MdEvent,
    href: routes.events.list,
    permissions: [permissions.events.list],
  },
  [SidebarPagesEnum.FILE_TRANSMISSIONS]: {
    referenceId: ReferenceIdEnum['file-transmissions'],
    icon: HiUpload,
    href: routes.fileTransmissions.board(),
  },
  [SidebarPagesEnum.LIST_DEVICES]: {
    referenceId: ReferenceIdEnum.devices,
    icon: MdDevices,
    href: routes.devices.list,
  },
  [SidebarPagesEnum.LIST_LOANS]: {
    referenceId: ReferenceIdEnum.loans,
    icon: FaHandHoldingUsd,
    href: routes.banking.loans.list,
    isNew: false,
  },
  [SidebarPagesEnum.LIST_LAST_TRANSACTIONS]: {
    referenceId: ReferenceIdEnum['last-transactions'],
    icon: AiFillWallet,
    href: routes.banking.transactions.list,
    comingSoon: true,
    isDisabled: true,
  },
  [SidebarPagesEnum.RECHARGES]: {
    referenceId: ReferenceIdEnum.recharges,
    icon: BsFillLightningChargeFill,
    href: routes.banking.recharges.list,
    comingSoon: true,
    isDisabled: true,
  },
  [SidebarPagesEnum.BILL_PAYMENTS]: {
    referenceId: ReferenceIdEnum['bill-payments'],
    icon: FaBarcode,
    href: routes.banking.billPayments.list,
    comingSoon: true,
    isDisabled: true,
  },
  [SidebarPagesEnum.PRODUCTS_BATCH_UPDATE]: {
    referenceId: ReferenceIdEnum['products-batch-update'],
    icon: TbArrowsUpDown,
    href: routes.products.batchUpdate,
    isNew: false,
  },
  [SidebarPagesEnum.PRODUCTS_PRICE_LIST]: {
    referenceId: ReferenceIdEnum['products-price-list'],
    icon: BsTable,
    href: routes.products.priceLists,
    isNew: false,
    comingSoon: false,
    isDisabled: false,
  },
  [SidebarPagesEnum.PRODUCTS_IMPORT]: {
    referenceId: ReferenceIdEnum['products-import'],
    icon: BsDownload,
    href: routes.products.productsImport,
    isNew: true,
    comingSoon: false,
    isDisabled: false,
  },
  [SidebarPagesEnum.PERMISSIONS_GRANTED]: {
    referenceId: ReferenceIdEnum['permissions-granted'],
    icon: AiOutlineAudit,
    href: routes.audits.permissionsGranted.list,
    isNew: true,
  },
  [SidebarPagesEnum.CUSTOM_FIELDS]: {
    referenceId: ReferenceIdEnum['custom-fields'],
    icon: MdOutlineDashboardCustomize,
    href: routes.customFields.list,
    isNew: true,
    permissions: [permissions.customFields.list],
  },
};

export const SideBarNav: FC = memo(() => {
  const { user } = useAuth();
  const { sidebarData } = useDynamicSidebar();

  const hasOnBoarding = true;

  const sortItems = (a, b): number =>
    a.order === b.order ? 0 : a.order < b.order ? -1 : 1;

  const dynamicSectionList: Array<NavSectionProps> = useMemo(
    () =>
      sidebarData
        ?.sort(sortItems)
        .map((sidebarItem) => {
          const { group: tag, customTitle: title, items: data } = sidebarItem;
          const tabs =
            data
              .sort(sortItems)
              .filter((tab) => tab.status === 'active')
              ?.map((sidebarTab) => ({
                label: sidebarTab.customTitle,
                ...tabProps[sidebarTab._id],
              })) || [];

          const shouldTabsStartsVisible =
            tag === 'configurations' || tag === 'stock' ? hasOnBoarding : true;

          return {
            tag,
            title,
            shouldTabsStartsVisible,
            tabs,
          };
        })
        .filter((group) => group.tabs?.length > 0) || [],
    [hasOnBoarding, sidebarData]
  );

  return (
    <Container>
      {dynamicSectionList.map((item) => {
        const doesntHaveAllPermissions = item.tabs?.every(
          (tab) =>
            !validateUserPermissions({
              user,
              permissions: tab.permissions,
            })
        );

        if (doesntHaveAllPermissions) return null;

        return (
          <NavSection
            key={item.tag}
            tag={item.tag}
            title={item.title}
            tabsStartsVisible
          >
            {item.tabs.map((tab) => {
              const isWithValidPermissions = validateUserPermissions({
                user,
                permissions: tab.permissions,
              });

              if (!isWithValidPermissions) return null;

              return tab.href ? (
                <NavItem
                  key={tab.referenceId}
                  id={tab.referenceId}
                  isDisabled={tab.isDisabled}
                  comingSoon={tab.comingSoon}
                  isNew={tab.isNew}
                  shouldMatchExactHref={tab.shouldMatchExactHref}
                  icon={tab.icon}
                  href={tab.href}
                >
                  {tab.label}
                </NavItem>
              ) : (
                <></>
              );
            })}
          </NavSection>
        );
      })}
    </Container>
  );
});
